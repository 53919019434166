<script setup>
import { ref, reactive, onMounted, defineEmits } from 'vue'
// import { formatCurrency, formatNumber, formatDate } from '@/helpers'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanApotekerService from '@/services/LaporanApotekerService'
import GolonganObatService from '@/services/GolonganObatService'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanApotekerService()
const golonganService = new GolonganObatService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)
let isLoadingPrint = ref(false)
let isLoading = ref(false)
let form = reactive({
  triwulan: null,
  golongan: null,
  type: 'masuk',
  tahun: new Date().getFullYear()
})
let submitted = ref(false)
let list_golongan = ref([])
let list_type = ref([])
let list_triwulan = ref([])
let list_tahun = ref([])

const rules = {
  golongan: {
    required: helpers.withMessage('Golongan harus diisi.', required),
  },
  type: {
    required: helpers.withMessage('Jenis harus diisi.', required),
  },
  triwulan: {
    required: helpers.withMessage('Periode triwulan harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  const res = await golonganService.get('?sort=nama')
  let list_golongan_reguler = res.data.data.filter(
    (e) =>
      e.nama.toUpperCase() === 'REGULER'
  )
  list_golongan.value = list_golongan_reguler[0].golongan_sub.filter(
    (e) =>
      e.nama.toUpperCase() === 'BEBAS' || e.nama.toUpperCase() === 'BEBAS TERBATAS' || e.nama.toUpperCase() === 'DAFTAR G / OBAT KERAS'
  )
  list_type.value = [
    { id: 'masuk', nama: 'Masuk' },
    { id: 'keluar', nama: 'Keluar' },
  ]
  list_triwulan.value = [
    { id: '1', nama: '01 Jan - 31 Mar' },
    { id: '2', nama: '01 Apr - 31 Jun' },
    { id: '3', nama: '01 Jul - 30 Sep' },
    { id: '4', nama: '01 Okt - 31 Des' },
  ]

  const currentYear = new Date().getFullYear();
  list_tahun.value = Array.from({ length: 5 }, (_, index) => currentYear - index);
})

function onPrint(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  isLoadingPrint.value = true
  const triwulan = form.triwulan
  const tahun = form.tahun
  let golonganId = 0
  let type = 'masuk'
  if (form.golongan) golonganId = form.golongan
  if (form.type) type = form.type
  isLoading.value = true
  laporanService
    .exportLaporanSiOdieTriwulan(triwulan, golonganId, type, tahun)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'laporan-apoteker-siodie-triwulan.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Apoteker Si-Odie Triwulan', toast)
    })
    .finally(() => ((isLoadingPrint.value = false), (isLoading.value = false)))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onExport(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  isLoadingExport.value = true
  const triwulan = form.triwulan
  const tahun = form.tahun
  let golonganId = 0
  let type = 'masuk'
  if (form.golongan) golonganId = form.golongan
  if (form.type) type = form.type
  isLoading.value = true
  laporanService
    .exportLaporanSiOdieExportTriwulan(triwulan, golonganId, type, tahun)
    .then((res) => {
      isLoadingExport.value = false
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'laporan-apoteker-siodie-triwulan.xls')
      document.body.appendChild(link)
      link.click()
    })

    .catch((err) => {
      errorHandler(err, 'Data Apoteker Si-Odie Triwulan', toast)
    })
    .finally(() => ((isLoadingExport.value = false), (isLoading.value = false)))
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.type = 'masuk'
  form.golongan = null
  form.triwulan = null
  form.tahun = new Date().getFullYear()
}
</script>

<template>
  <fx-report-container
    title="LAPORAN SI-ODIE TRIWULAN"
    :loading="isLoadingExport"
    @toggle="onToggle"
  >
    <template #content>
      <form>
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-6">
            <Dropdown
              v-model="form.golongan"
              :options="list_golongan"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Golongan"
              class="w-full"
              :class="{
                'p-invalid': v$.golongan.$invalid && submitted,
              }"
            />
            <small
              v-if="
                (v$.golongan.$invalid && submitted) ||
                v$.golongan.$pending.$response
              "
              class="p-error"
              >{{ v$.golongan.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <Dropdown
              v-model="v$.triwulan.$model"
              :options="list_triwulan"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Periode"
              class="w-full"
              :class="{
                'p-invalid': v$.triwulan.$invalid && submitted,
              }"
            />
            <small
              v-if="
                (v$.triwulan.$invalid && submitted) ||
                v$.triwulan.$pending.$response
              "
              class="p-error"
              >{{ v$.triwulan.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Dropdown
              v-model="form.tahun"
              :options="list_tahun"
              placeholder="Pilih Tahun"
              class="w-full"
            />
          </div>
          <div class="field col-12 md:col-3">
            <Dropdown
              v-model="form.type"
              :options="list_type"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Jenis"
              class="w-full"
              :class="{
                'p-invalid': v$.type.$invalid && submitted,
              }"
            />
            <small
              v-if="
                (v$.type.$invalid && submitted) || v$.type.$pending.$response
              "
              class="p-error"
              >{{ v$.type.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-5">
            <!--<Button type="submit" icon="pi pi-file-pdf" label="Export" />-->
            <Button
              :loading="isLoadingPrint"
              type="button"
              icon="pi pi-file-pdf"
              label="Cetak"
              @click="onPrint(!v$.$invalid)"
            />
            <Button
              :loading="isLoadingExport"
              type="button"
              icon="pi pi-file-excel"
              label="Export"
              class="p-button-success ml-2"
              @click="onExport(!v$.$invalid)"
            />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2 md:ml-0 xl:ml-2 md:mt-2 xl:mt-0"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
